export const getImageOrientation = (image: Object) => {
  if (!image?.width ) return null
  const width = image?.width
  const height = image?.height
  if (width > height) {
    return 'landscape'
  } else if (width < height) {
    return 'portrait'
  } else {
    return 'square'
  }
}
